import React, { useState, useEffect } from "react"

import "./privacy-policy.styles.scss"

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null)

  useEffect(() => {
    fetch("https://www.iubenda.com/api/privacy-policy/16702960")
      .then(response => response.json())
      .then(data => setPrivacyPolicy(data))
  }, [])

  return (
    <div className="content is-size-6">
      {privacyPolicy && (
        <div className="privacy-policy" dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}></div>
      )}
    </div>
  )
}
 
export default PrivacyPolicy
